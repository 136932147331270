@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #1b223a inset !important;
}