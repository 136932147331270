.graph__scrollbox {
  overflow: auto;
  height: 90vh;
  width: inherit;
  margin: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph__inner {
  width: 1700px;
  height: 1200px;
}

.graph__svg {
  width: 100%;
  height: 100%;
}
.graph__tooltip {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 5px;
  visibility: hidden;
}
.tooltip__heading {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 7px;
}
.tooltip__entryname {
  display: 'inline-block';
  width: 4.5rem;
}
.labels text {
  fill: #aaa;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
}

.links__line {
  stroke-width: 2px;
}

.links__line--up {
  stroke: green;
}

.links__line--down {
  stroke: red;
}
.links__line--degrade {
  stroke: orange;
}

.links__line--noStatus {
  stroke: #999;
}
.tooltip {
  background: #f1f0f1;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  left: 300px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 220px;
  width: 80px;
  z-index: 20;
}
.graph__zoom {
  display: flex;
  position: absolute;
  z-index: 3;
  top: 90%;
  left: 80%;
}

/********** Range Input Styles **********/
/*Range Reset*/
.zoom__slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 16px;
}

/* Removes default focus */
.zoom__slider:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
.zoom__slider::-webkit-slider-runnable-track {
  background-color: #f61463;
  border-radius: 0.5rem;
  height: 0.4rem;
}

/* slider thumb */
.zoom__slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px;
  border-radius: 50%;

  /*custom styles*/
  background-color: white;
  height: 0.9rem;
  width: 0.9rem;
}

.zoom__slider:focus::-webkit-slider-thumb {
  border: 1px solid white;
  outline: 2px solid white;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
.zoom__slider::-moz-range-track {
  background-color: #f61463;
  border-radius: 0.5rem;
  height: 0.4rem;
}

/* slider thumb */
.zoom__slider::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 50%; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: white;
  height: 0.9rem;
  width: 0.9rem;
}

.zoom__slider:focus::-moz-range-thumb {
  border: 1px solid white;
  outline: 2px solid white;
  outline-offset: 0.125rem;
}

.zoomin__icon {
  cursor: pointer;
}

.zoomout__icon {
  cursor: pointer;
}
