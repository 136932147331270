.form-box {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  row-gap: 40px;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background-color: white;
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  height: 100vh;
  width: 100%;
  background-image: url('../assets/loginSvg.svg');
  text-align: center;
  background-repeat: no-repeat;
}
.login-smart {
  color: #00b7a4;
}
.login-zopsmart-logo {
  display: flex;
  align-items: center;
  font-size: 30px;
}
