.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #262b48;
  padding: 0rem 2rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  border-bottom: 1px solid #1b213a;
}

.navbar__menu {
  position: absolute;
  top: 0rem;
  right: 1rem;
  display: none;
}

.navbar__buttonbar {
  font-size: 1.2rem;
  align-items: center;
  font-weight: 100;
}

.navbar__buttonbar ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar__buttonbar li {
  list-style: none;
}

.navbar__button {
  text-decoration: none;
  float: left;
  display: block;
  box-shadow: none;
  padding: 1rem;
  width: 8.5rem;
  text-align: center;
}

.navbar__button:hover {
  background-color: #373c56;
}

.navbar__btn {
  background-color: #ff4385;
}

.navbar__title {
  font-size: 2rem;
}

.navbar__title--red {
  font-weight: 700;
  color: #f50057;
}

@media screen and (max-width: 700px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 2rem;
  }
  .navbar__menu {
    display: block;
  }

  .navbar__buttonbar {
    margin-top: 1rem;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .navbar__buttonbar ul {
    flex-direction: column;
    width: 100%;
  }

  .navbar__buttonbar li {
    display: flex;
    justify-content: center;
  }

  .navbar__button {
    width: 100%;
  }

  .navbar__buttonbar.active {
    display: flex;
  }
}
