@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #1b223a inset !important;
}
.App_lds-loading__1zgxJ {
  position: fixed;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 47%;
}

.App_lds-loading__1zgxJ div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  -webkit-animation: App_lds-loading__1zgxJ 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: App_lds-loading__1zgxJ 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.App_lds-loading__1zgxJ div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.App_lds-loading__1zgxJ div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.App_lds-loading__1zgxJ div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
@-webkit-keyframes App_lds-loading__1zgxJ {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
@keyframes App_lds-loading__1zgxJ {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.D3NetworkGraph_graph__scrollbox__2D4w9 {
  overflow: auto;
  height: 90vh;
  width: inherit;
  margin: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.D3NetworkGraph_graph__inner__DAjxm {
  width: 1700px;
  height: 1200px;
}

.D3NetworkGraph_graph__svg__TJFAS {
  width: 100%;
  height: 100%;
}
.D3NetworkGraph_graph__tooltip__SgBlZ {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  background-color: white;
  color: black;
  padding: 15px;
  border-radius: 5px;
  visibility: hidden;
}
.D3NetworkGraph_tooltip__heading__2_YO1 {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 7px;
}
.D3NetworkGraph_tooltip__entryname__2Rp4m {
  display: 'inline-block';
  width: 4.5rem;
}
.D3NetworkGraph_labels__3IIzc text {
  fill: #aaa;
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
}

.D3NetworkGraph_links__line__2v79W {
  stroke-width: 2px;
}

.D3NetworkGraph_links__line--up__3EMXh {
  stroke: green;
}

.D3NetworkGraph_links__line--down__3DPhK {
  stroke: red;
}
.D3NetworkGraph_links__line--degrade__aLsQx {
  stroke: orange;
}

.D3NetworkGraph_links__line--noStatus__2mFEH {
  stroke: #999;
}
.D3NetworkGraph_tooltip__1UsZw {
  background: #f1f0f1;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  left: 300px;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 220px;
  width: 80px;
  z-index: 20;
}
.D3NetworkGraph_graph__zoom__3rH2N {
  display: flex;
  position: absolute;
  z-index: 3;
  top: 90%;
  left: 80%;
}

/********** Range Input Styles **********/
/*Range Reset*/
.D3NetworkGraph_zoom__slider__12lJ9 {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 16px;
}

/* Removes default focus */
.D3NetworkGraph_zoom__slider__12lJ9:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
.D3NetworkGraph_zoom__slider__12lJ9::-webkit-slider-runnable-track {
  background-color: #f61463;
  border-radius: 0.5rem;
  height: 0.4rem;
}

/* slider thumb */
.D3NetworkGraph_zoom__slider__12lJ9::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px;
  border-radius: 50%;

  /*custom styles*/
  background-color: white;
  height: 0.9rem;
  width: 0.9rem;
}

.D3NetworkGraph_zoom__slider__12lJ9:focus::-webkit-slider-thumb {
  border: 1px solid white;
  outline: 2px solid white;
  outline-offset: 0.125rem;
}

/******** Firefox styles ********/
/* slider track */
.D3NetworkGraph_zoom__slider__12lJ9::-moz-range-track {
  background-color: #f61463;
  border-radius: 0.5rem;
  height: 0.4rem;
}

/* slider thumb */
.D3NetworkGraph_zoom__slider__12lJ9::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 50%; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  background-color: white;
  height: 0.9rem;
  width: 0.9rem;
}

.D3NetworkGraph_zoom__slider__12lJ9:focus::-moz-range-thumb {
  border: 1px solid white;
  outline: 2px solid white;
  outline-offset: 0.125rem;
}

.D3NetworkGraph_zoomin__icon__39l4J {
  cursor: pointer;
}

.D3NetworkGraph_zoomout__icon__2OpBZ {
  cursor: pointer;
}

@-webkit-keyframes loading__cirlce {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loading__cirlce {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loading__cirlce div {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 4px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.loading__cirlce div {
  -webkit-animation: loading__cirlce 1s linear infinite;
          animation: loading__cirlce 1s linear infinite;
  top: 25px;
  left: 25px;
}
.loading__spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading__cirlce {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(0.88);
          transform: translateZ(0) scale(0.88);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.loading__cirlce div {
  box-sizing: content-box;
}

.Navbar_navbar__32aHN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #262b48;
  padding: 0rem 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  border-bottom: 1px solid #1b213a;
}

.Navbar_navbar__menu__zewiZ {
  position: absolute;
  top: 0rem;
  right: 1rem;
  display: none;
}

.Navbar_navbar__buttonbar__qeUoc {
  font-size: 1.2rem;
  align-items: center;
  font-weight: 100;
}

.Navbar_navbar__buttonbar__qeUoc ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.Navbar_navbar__buttonbar__qeUoc li {
  list-style: none;
}

.Navbar_navbar__button__1ThAX {
  text-decoration: none;
  float: left;
  display: block;
  box-shadow: none;
  padding: 1rem;
  width: 8.5rem;
  text-align: center;
}

.Navbar_navbar__button__1ThAX:hover {
  background-color: #373c56;
}

.Navbar_navbar__btn__3VMG6 {
  background-color: #ff4385;
}

.Navbar_navbar__title__32GTy {
  font-size: 2rem;
}

.Navbar_navbar__title--red__23N3z {
  font-weight: 700;
  color: #f50057;
}

@media screen and (max-width: 700px) {
  .Navbar_navbar__32aHN {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 2rem;
  }
  .Navbar_navbar__menu__zewiZ {
    display: block;
  }

  .Navbar_navbar__buttonbar__qeUoc {
    margin-top: 1rem;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .Navbar_navbar__buttonbar__qeUoc ul {
    flex-direction: column;
    width: 100%;
  }

  .Navbar_navbar__buttonbar__qeUoc li {
    display: flex;
    justify-content: center;
  }

  .Navbar_navbar__button__1ThAX {
    width: 100%;
  }

  .Navbar_navbar__buttonbar__qeUoc.Navbar_active__2CNyM {
    display: flex;
  }
}

.form-box {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  grid-row-gap: 40px;
  row-gap: 40px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.nsm7Bb-HzV7m-LgbsSe-MJoBVe {
  background-color: white;
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/loginSvg.ff5ba8fa.svg);
  text-align: center;
  background-repeat: no-repeat;
}
.login-smart {
  color: #00b7a4;
}
.login-zopsmart-logo {
  display: flex;
  align-items: center;
  font-size: 30px;
}

