@keyframes loading__cirlce {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loading__cirlce div {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 4px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.loading__cirlce div {
  animation: loading__cirlce 1s linear infinite;
  top: 25px;
  left: 25px;
}
.loading__spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.loading__cirlce {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.88);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loading__cirlce div {
  box-sizing: content-box;
}
